<template>
  <v-container id="regular-tables" fluid tag="section">



    <base-material-card color="success" icon="mdi-clipboard-plus" v-if="usertype == 'admin'" title="Add New Partner"
      class="px-5 py-3">

      <v-container class="py-0">
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field label="Partner Name" class="purple-input" outlined dense v-model="Dist.D_Name" />
          </v-col>
          <v-col cols="12" md="12">
            <v-textarea label="Partner Description" class="purple-input" v-model="Dist.D_Descriptions" outlined dense />
          </v-col>

          <v-col cols="6" md="6">
            <v-switch v-model="IsSoftware" flat :label="'Is Software Partner'"></v-switch>

          </v-col>

          <v-col cols="12" md="12">
            <template>
              <v-file-input outlined dense accept="image/png, image/jpeg, image/bmp" placeholder="Pick an Logo"
                prepend-icon="mdi-camera" v-model="Dist.Logo" label="Logo"></v-file-input>
            </template>
          </v-col>

          <v-col cols="12" md="12">
            <template>
              <v-file-input outlined dense accept="image/png, image/jpeg, image/bmp" placeholder="Pick an background"
                prepend-icon="mdi-camera" v-model="Dist.D_Image" label="background"></v-file-input>
            </template>

          </v-col>


        </v-row>
        <template>
          <v-btn color="success" class="mr-0" @click="InsertItemToDB">
            Save
          </v-btn>
        </template>



      </v-container>
    </base-material-card>

    <base-material-card icon="mdi-clipboard-text" class="px-5 py-3">
      <v-data-table :headers="headers" :items="Distribution" sort-by="calories" class="elevation-1">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Partners List</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="1000px">
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="12">
                        <v-text-field label="Partner Name" class="purple-input" outlined dense
                          v-model="editedItem.D_Name" />
                      </v-col>
                      <v-col cols="12" md="12">
                        <v-textarea label="Partner Description" class="purple-input" v-model="editedItem.D_Descriptions"
                          outlined dense />
                      </v-col>

                      <v-col cols="6" md="6">
                        <v-switch v-model="editedItem.IsSoftware" flat :label="'Is Software Partner'"></v-switch>

                      </v-col>
                      <v-col cols="12" md="12">
                        <template>
                          <v-file-input outlined dense accept="image/png, image/jpeg, image/bmp"
                            placeholder="Pick an Logo" prepend-icon="mdi-camera" v-model="editedItem.Logo"
                            label="Logo"></v-file-input>
                        </template>
                      </v-col>

                      <v-col cols="12" md="12">
                        <template>
                          <v-file-input outlined dense accept="image/png, image/jpeg, image/bmp"
                            placeholder="Pick an background" prepend-icon="mdi-camera" v-model="editedItem.D_Image"
                            label="background"></v-file-input>
                        </template>

                      </v-col>



                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="1000px">
              <v-card>
                <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="initialize">
            Reset
          </v-btn>
        </template>

      </v-data-table>
    </base-material-card>


    <div class="py-3" />

  </v-container>
</template>

<script>

import { db, fb } from '@/db'
import { mapMutations, mapState } from 'vuex'


export default {
  data() {
    return {
      Dist: {
        D_Name: '',
        D_Descriptions: '',
        D_Image: '',
        Logo: '',
        IsSoftware: ''
      },
      headers: [
        {
          text: 'Distribution Name',
          align: 'start',
          sortable: false,
          value: 'D_Name',
        },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      form: {
        Images: [],
        snackbar: false,
        snakMsg: "Uploaded success"
      },
      ImageFire: [],
      dialog: false,
      dialogDelete: false,
      search: '',
      desserts: [],
      editedIndex: -1,
      finaleditItem: {},
      editedItem: {
      },
      defaultItem: {
      },

    }
  },
  computed: {

    Distribution() {
      return this.$store.state.AllDistribution
    },

    usertype() {
      return this.$store.state.userType
    },
    formTitle() {
      return 'Partners'
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {

      val || this.closeDelete()
    },
  },

  methods: {
    toggleDetails(item, index) {
      this.$set(this.MainItem[index], '_toggled', !item._toggled)
      this.collapseDuration = 300
      this.$nextTick(() => { this.collapseDuration = 0 })
    },
    InsertItemToDB() {
    debugger
      var item = this.Dist;
      var context = this;

      db.collection('Distribution')
        .add({
          D_Name: item.D_Name,
          D_Descriptions: item.D_Descriptions,
          Order: 99
        })
        .then(docRef => {
          debugger
          var uuid = require("uuid");

          var storageRef = fb.storage().ref('Partner/Back-' + docRef.id + '/' + uuid.v4())
          let UploadTask = storageRef.put(item.D_Image);

          UploadTask.on('state_changed', async function (snapshot) {
          }, function (error) {
          }, function () {
            UploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
              item.D_Image = downloadURL;
              storageRef = fb.storage().ref('Partner/Logo-' + docRef.id + '/' + uuid.v4())
              UploadTask = storageRef.put(item.Logo);
              UploadTask.on('state_changed', async function (snapshot) {
              }, function (error) {

              }, function () {

                UploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {

                  return db.collection('Distribution')
                    .doc(docRef.id)
                    .update({ "D_Image": item.D_Image, "D_ID": docRef.id, "Logo": downloadURL })
                    .then(_ => {
                      alert("Partner has been added successfully");
                    })
                })
              })
            })
          })

        })
    },
    rowClick(item, row) {
      var found = false;
      for (var i = 0; i < this.selected.length; i++) {
        if (this.selected[i].ItemName == item.ItemName) {
          found = true;
          break;
        }
      }
      if (!found) {
        var newitem = Object.assign({}, item);
        this.Finalitem.Cost = this.Finalitem.Cost + parseFloat(newitem.ItemCost) + parseFloat(newitem.AdditionalCost);
        this.selected.push(newitem);
      } else {
        alert("Item Already Used");
      }

    },
    filterOnlyCapsText(value, search, item) {
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) !== -1
    },
    editItem(item) {

      this.editedIndex = this.Distribution.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {

      this.editedIndex = this.Distribution.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteMainItem(item, index) {
      var context = this;
      db.collection('Distribution').doc(item.ID)
        .delete().then(_ => {
          alert("Distribution Was Deleted ...")
          context.MainItem.splice(index);
        })

    },
    deleteItemConfirm() {

      db.collection('Distribution').doc(this.Distribution[this.editedIndex].id)
        .delete().then(_ => {
          alert("Distribution Was Deleted ...")
          context.MainItem.splice(index);
        })
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {

      this.dialogDelete = false
      this.deleteMainItem(this.editedItem, this.editedIndex);
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
    },
    format_date: function (value) {

      let myDate = new Date(value.seconds * 1000)
      var now = new Date()


      if (!(myDate.getDate() == now.getDate() && myDate.getMonth() == now.getMonth()
        && myDate.getFullYear() == now.getFullYear())) {
        let options = {
          weekday: "long", year: "numeric", month: "short", day: "numeric"
        };
        return myDate.toLocaleDateString("en-us", options)
      }
      else {
        let options = {
          hour: "2-digit", minute: "2-digit"
        };
        return ("Today , " + myDate.toLocaleTimeString("en-us", options))
      }



    },

    save() {
      var context = this;
      this.finaleditItem = Object.assign({}, this.editedItem);
      if (this.editedIndex > -1) {

        db.collection('Distribution').doc(this.editedItem.D_ID)
          .update({
            "D_Name": context.editedItem.D_Name,
            "D_Descriptions": context.editedItem.D_Descriptions,
            "IsSoftware": context.editedItem.IsSoftware
          }).then(_ => {
            var uuid = require("uuid");

            if (typeof context.finaleditItem.D_Image !== 'string') {


              var storageRef = fb.storage().ref('Partner/Back-' + context.finaleditItem.D_ID + '/' + uuid.v4())
              let UploadTask = storageRef.put(context.finaleditItem.D_Image);

              UploadTask.on('state_changed', async function (snapshot) {
              }, function (error) {
              }, function () {
                UploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                  context.finaleditItem.D_Image = downloadURL;
                  if (typeof context.finaleditItem.Logo !== 'string') {
                    return db.collection('Distribution')
                      .doc(context.finaleditItem.D_ID)
                      .update({ "D_Image": context.finaleditItem.D_Image })
                      .then(_ => {
                        context.updateLogoImage()
                      })
                  } else {
                    return db.collection('Distribution')
                      .doc(context.finaleditItem.D_ID)
                      .update({ "D_Image": context.finaleditItem.D_Image })
                      .then(_ => {
                        alert("Partner Updated successfully");
                      })
                  }
                })
              })
            } else {
              if (typeof context.finaleditItem.Logo !== 'string') {
                context.updateLogoImage()
              } else
                alert("Partner Updated successfully");
            }
          })

      } else {
        this.selected.push(this.editedItem)
      }
      this.close()


    },

    updateLogoImage() {
      var context = this
      var uuid = require("uuid");
      var storageRef = fb.storage().ref('Partner/Logo-' + context.finaleditItem.D_ID + '/' + uuid.v4())
      var UploadTask = storageRef.put(this.finaleditItem.Logo);
      UploadTask.on('state_changed', async function (snapshot) {
      }, function (error) {

      }, function () {

        UploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {

          return db.collection('Distribution')
            .doc(context.finaleditItem.D_ID)
            .update({ "Logo": downloadURL })
            .then(_ => {
              alert("Partner has been added successfully");
            })
        })
      })
    },
    onPickFile() {
      this.$refs.fileinput.click();
    },
    ondelete(index) {
      this.form.Images.splice(index, 1);

    },


  },
  created() {
    this.$store.dispatch('bindAllDistribution');

  }
}
</script>


<style lang="scss">
.tim-note {
  bottom: 10px;
  color: #c0c1c2;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  left: 0;
  margin-left: 20px;
  width: 260px;
}
</style>
